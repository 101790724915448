import { generateSubscriptionAndGetWallet } from '@/API/wallet';
import ModalPayment from '@/components/Profile/ModalPayment';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import {
  Card,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  Divider,
  Icon,
  ListItemIcon,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';

const colors = {
  gold: {
    primary: '#FFD700',
  },
  premium: {
    primary: '#F7A429',
  },
  basic: {
    primary: '#017FC7',
  },
};

export default function GetPremium({ renewing = false }) {
  const { t } = useTranslation('getPremiumBackdrop');

  const itemsPremiumPlus = [
    t('getPremiumButton.premiumPlusContent.items.plan_0'),
    t('getPremiumButton.premiumPlusContent.items.plan_1'),
    t('getPremiumButton.premiumPlusContent.items.plan_2'),
    t('getPremiumButton.premiumPlusContent.items.plan_3'),
    t('getPremiumButton.premiumPlusContent.items.plan_4'),
    t('getPremiumButton.premiumPlusContent.items.plan_5'),
    t('getPremiumButton.premiumPlusContent.items.plan_6'),
    t('getPremiumButton.premiumPlusContent.items.plan_7'),
  ];

  const itemsPremium = [
    t('getPremiumButton.premiumContent.items.plan_0'),
    t('getPremiumButton.premiumContent.items.plan_1'),
    t('getPremiumButton.premiumContent.items.plan_2'),
    t('getPremiumButton.premiumContent.items.plan_3'),
    t('getPremiumButton.premiumContent.items.plan_4'),
  ];

  const itemsBasic = [
    t('getPremiumButton.opportunityContent.items.plan_0'),
    t('getPremiumButton.opportunityContent.items.plan_1'),
    t('getPremiumButton.opportunityContent.items.plan_2'),
    t('getPremiumButton.opportunityContent.items.plan_3'),
  ];

  const itemsRenew = [
    'Sigue disfrutando de la app',
    'Continua ganando de todos los referidos de tu equipo',
    'Renueva para seguir recibiendo tus comisiones',
  ];

  const [typeProduct, setTypeProduct] = useState(null);
  const [currentSession, setCurrentSession] = useState(null);
  const [walletAddressToPay, setAddressWalletToPay] = useState(null);
  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const [openModalPayment, setOpenModalPayment] = useState(false);
  const [itemsPayment, setItemsPayment] = useState({
    title: '',
    price: 0,
    fee: t('getPremiumButton.fee.fee'),
    priceFee: 0,
  });

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem('sessionMitox'));
    setCurrentSession(session);
  }, []);

  const getWallet = async type => {
    setLoadingSubscription(true);

    setTypeProduct(type);

    const resp = await generateSubscriptionAndGetWallet(currentSession.token, type);
    if (resp.status === 200) {
      const hasPremiumSubscription = currentSession.user.userSubscription.some(
        ({ subscription, status_pay }) => subscription === 'Premium Suscription' && status_pay === 1
      );

      const fee = {
        title:
          type === 1 ? t('getPremiumButton.fee.titleType1') : t('getPremiumButton.fee.titleType2'),
        price: type === 1 ? 149 : type === 4 ? (hasPremiumSubscription ? 1700 : 2000) : 60,
        fee: t('getPremiumButton.fee.fee'),
        priceFee: type === 1 ? 11 : 6,
      };

      setItemsPayment(fee);
      setAddressWalletToPay(resp.data[0].product_wallet_address);

      setLoadingSubscription(false);
      setOpenModalPayment(true);
    }
  };

  return (
    <>
      <ModalPayment
        session={currentSession && currentSession}
        walletAddress={walletAddressToPay}
        open={openModalPayment}
        typeProduct={typeProduct}
        handleClose={e => setOpenModalPayment(false)}
        items={itemsPayment && itemsPayment}
      />
      <Grid container spacing={2} alignItems='stretch'>
        {!renewing && (
          <Grid item xs={12} md={6}>
            <Card
              variant='elevation'
              elevation={4}
              sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            >
              <Box position='relative' sx={{ backgroundColor: colors.premium.primary }}>
                <Box position='absolute' top={0} left={0} width='100%' height='100%'>
                  <Typography sx={{ p: 1 }} fontWeight={600} variant='h5' color='white'>
                    {t('getPremiumButton.premiumContent.title')}
                  </Typography>
                </Box>
                <Typography sx={{ p: 1, opacity: 0 }} fontWeight={600} variant='h5' color='white'>
                  {t('getPremiumButton.premiumPlusContent.title')}
                </Typography>
              </Box>
              <List>
                {itemsPremium.map(item => (
                  <>
                    <ListItem alignItems='center'>
                      <ListItemIcon sx={{ minWidth: 40 }}>
                        <CheckIcon sx={{ color: colors.premium.primary }} />
                      </ListItemIcon>
                      <Typography fontWeight={600} variant='body1' color='textPrimary'>
                        {item}
                      </Typography>
                    </ListItem>
                    <Divider />
                  </>
                ))}
              </List>
              <LoadingButton
                loading={loadingSubscription}
                onClick={() => getWallet(1)}
                sx={{
                  width: '80%',
                  margin: 'auto',
                  fontWeight: 600,
                  fontSize: '1rem',
                  my: 2,
                  marginTop: 'auto',
                  backgroundColor: colors.premium.primary,
                }}
                variant='contained'
                startIcon={<WorkspacePremiumIcon />}
              >
                {t('getPremiumButton.premiumContent.buttonGet')}
              </LoadingButton>
            </Card>

            {/* #017FC7 */}
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Card
            variant='elevation'
            elevation={4}
            sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <Box position='relative' sx={{ backgroundColor: colors.basic.primary }}>
              <Box position='absolute' top={0} left={0} width='100%' height='100%'>
                <Typography sx={{ p: 1 }} fontWeight={600} variant='h5' color='white'>
                  {t('getPremiumButton.opportunityContent.title')}
                </Typography>
              </Box>
              <Typography sx={{ opacity: 0, p: 1 }} fontWeight={600} variant='h5' color='white'>
                {t('getPremiumButton.premiumPlusContent.title')}
              </Typography>
            </Box>
            <List>
              {(renewing ? itemsRenew : itemsBasic).map(item => (
                <>
                  <ListItem alignItems='center'>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <CheckIcon sx={{ color: colors.basic.primary }} />
                    </ListItemIcon>
                    <Typography fontWeight={600} variant='body1' color='textPrimary'>
                      {item}
                    </Typography>
                  </ListItem>
                  <Divider />
                </>
              ))}
            </List>
            <LoadingButton
              loading={loadingSubscription}
              onClick={() => getWallet(2)}
              sx={{
                width: '80%',
                margin: 'auto',
                fontWeight: 600,
                fontSize: '1rem',
                my: 2,
                marginTop: 'auto',
                backgroundColor: colors.basic.primary,
              }}
              variant='contained'
              startIcon={<WorkspacePremiumIcon />}
            >
              {renewing ? 'Renovar' : t('getPremiumButton.opportunityContent.buttonGet')}
            </LoadingButton>
          </Card>
        </Grid>
      </Grid>

      {/* <LoadingButton
        loading={loadingSubscription}
        onClick={() => getWallet(2)}
        sx={{ width: '80%', margin: 'auto', fontWeight: 600, fontSize: '1rem' }}
        variant='contained'
        color='primary'
        startIcon={<WorkspacePremiumIcon />}
      >
        Get Premium
      </LoadingButton> */}
    </>
  );
}
