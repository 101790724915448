import { axiosBase } from './axiosBase';

const generateSubscriptionAndGetWallet = async (session, idProduct) => {
  const body = {
    idProduct,
  };
  const resp = await axiosBase('subscription/buy', 'POST', body, session);
  return resp;
};

const generateSubscriptionAndGetWalletUSDC = async (session, idProduct) => {
  const body = {
    idProduct,
  };
  const resp = await axiosBase('subscription/buy-usdc', 'POST', body, session);
  return resp;
};

const updateWalletUser = async (password, walletAddress, session) => {
  const body = {
    password,
    walletAddress,
  };
  const resp = await axiosBase('users/changeWallet', 'PUT', body, session);
  return resp;
};

const updateMailUser = async (password, mail, session) => {
  const body = {
    password,
    mail,
  };
  const resp = await axiosBase('users/changeEmail', 'PUT', body, session);
  return resp;
};

export { generateSubscriptionAndGetWallet, generateSubscriptionAndGetWalletUSDC, updateWalletUser, updateMailUser };
