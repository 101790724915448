import { Backdrop, Container, Paper, Typography } from '@mui/material';
import GetPremium from '../Dashboard/GeneralInfo/GetPremium';
import { useState } from 'react';
import validatePremiumAccess from '@/utils/validatePremiumAccess';
import { useTranslation } from 'react-i18next';

export default function GetPremiumBackdrop({
  session,
  renewing = false,
  disablePadding = false,
  fullHeight = false,
}) {
  const { t } = useTranslation('getPremiumBackdrop');

  return (
    <Container
      align='center'
      maxWidth='lg'
      fullWidth
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper
        sx={{
          p: disablePadding ? 0 : 4,
          maxHeight: fullHeight ? '100%' : '90%',
          overflowY: 'auto',
        }}
        align='center'
        elevation={0}
      >
        {!renewing && (
          <Typography fontWeight={600} sx={{ my: 1 }} variant='h4' color='textPrimary'>
            {t('mainTitle')}
          </Typography>
        )}
        <Typography sx={{ mb: 2 }} variant='h6' color='textPrimary'>
          {t('mainDescription')}
        </Typography>
        <GetPremium renewing={!renewing} />
      </Paper>
    </Container>
  );
}
