import { theme } from '@/utils/theme';

import {
  Link,
  Dialog,
  Container,
  Typography,
  IconButton,
  Icon,
  Divider,
  Button,
  Grid,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import ModalPaymentCustom from '@/components/Profile/ModalPayment/indexCustom';
import CloseIcon from '@mui/icons-material/Close';
import QRCode from 'react-qr-code';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

//GETFRONT SECTION
/*import { FrontPayload } from '@front-finance/link';
import { FrontApi } from '@front-finance/api';
import { FrontComponent } from './Front';*/

let iframeLinkTransfer = false;

import { generateSubscriptionAndGetWallet } from '@/API/wallet';

export default function ModalPayment({
  walletAddress,
  open,
  handleClose,
  session,
  items,
  typeProduct,
}) {
  // function that translate the page
  const { t } = useTranslation('getPremiumBackdrop');

  const [helperCopy, setHelperCopy] = useState({
    show: false,
    message: '',
  });

  const handleCopyIcon = text => {
    const { clipboard } = navigator;

    if (!clipboard || !clipboard.writeText) {
      return setHelperCopy({ show: true, message: 'Cannot access to your clipboard' });
    }

    try {
      clipboard.writeText(text).then(() => {
        setHelperCopy({ show: true, message: 'Copy successful' });
        setTimeout(() => {
          setHelperCopy({ show: false, message: '' });
        }, 1000);
      });
    } catch (error) {
      setHelperCopy({ show: true, message: error.message });
    }
  };

  //GETFRONT SECTION

  const [statusGetFront, setStatusGetFront] = useState('');

  const [iframeLink, setIframeLink] = useState(null);
  const [error, setError] = useState(null);
  const [payload, setPayload] = useState(null);
  const [openModalPaymentCustom, setOpenModalPaymentCustom] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);

  const getAuthLink = useCallback(async () => {
    setError(null);
    setIframeLink(null);
    const api = new FrontApi({
      baseURL: process.env.NEXT_PUBLIC_FRONT_API_URL,
      headers: {
        'x-client-id': process.env.NEXT_PUBLIC_CLIENT_ID,
        'x-client-secret': process.env.NEXT_PUBLIC_CLIENT_SECRET,
      },
    });

    const response = await api.managedAccountAuthentication.v1CataloglinkList({
      userId: 'mitox-user-' + JSON.parse(localStorage.getItem('sessionMitox')).user.id,
      callbackUrl: window.location.href,
    });

    const data = response.data;

    if (response.status !== 200 || !data?.content) {
      const error = (data && data.message) || response.statusText;
      console.error('Error!', error);
      setError(error);
    } else if (!data.content.iFrameUrl) {
      setError('Iframe url is empty');
    } else {
      setIframeLink(data.content.iFrameUrl);
    }
  }, []);

  //GETFRONT SECTION

  if (!session) {
    return <></>;
  } else {
    return (
      <>
        <Dialog
          maxWidth='md'
          fullWidth
          style={{ borderRadius: '3rem' }}
          open={open}
          onClose={handleClose}
        >
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={handleClose}>
                <Icon>
                  <CloseIcon />
                </Icon>
              </IconButton>
            </Box>
            {!termsAndConditions ? (
              <Container maxWidth='lg' sx={{ pb: 4 }}>
                <Box align='center'>
                  <Image
                    src='/images/graphics/mainLogo.png'
                    alt='logoReceipt'
                    width={200}
                    height={80}
                  />
                  <Typography variant='h5' fontWeight={600} color='textPrimary'>
                    Términos y condiciones
                  </Typography>
                  <br />
                </Box>
                <Box sx={{ width: '80%', m: 'auto' }}>
                  <Typography textAlign='center'>
                    Debes aceptar los{' '}
                    <Link href='/vip_contract.pdf' target='_blank'>
                      términos y condiciones
                    </Link>{' '}
                    para poder continuar.
                  </Typography>
                  <br />
                  <Grid display='flex' justifyContent='center' gap={2}>
                    <Button
                      onClick={() => {
                        handleClose();
                        setTermsAndConditions(false);
                      }}
                      variant='outlined'
                      color='primary'
                    >
                      Cancelar
                    </Button>
                    <Button
                      onClick={() => setTermsAndConditions(true)}
                      variant='contained'
                      color='primary'
                    >
                      Aceptar
                    </Button>
                  </Grid>
                </Box>
              </Container>
            ) : (
              <Container maxWidth='lg' sx={{ pb: 4 }}>
                <Box align='center'>
                  <Image
                    src='/images/graphics/mainLogo.png'
                    alt='logoReceipt'
                    width={200}
                    height={80}
                  />
                  {/* <Typography variant='h4' fontWeight={600} color='textPrimary'>
                  Mitox
                </Typography> */}
                  <Typography variant='h5' fontWeight={600} color='textPrimary'>
                    {'Select your payment method'}
                  </Typography>
                  <br />
                </Box>
                {/*<Box sx={{ width: '80%', m: 'auto' }}>
                  <br />
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12} align='center'>
                      <img
                        src={
                          'https://global-uploads.webflow.com/63bc23eb0c0864e3ab5cfeed/63bd4d53a74ad376a6047149_Group%204192.png'
                        }
                        alt='Front Logo'
                        width={'195px'}
                      />
                      <br />
                      <br />
                      {statusGetFront ? (
                        <Typography variant='h5' fontWeight={600} color='textPrimary'>
                          {statusGetFront}
                        </Typography>
                      ) : (
                        <Button onClick={() => getAuthLink()} variant='contained' color='primary'>
                          {t('getPremiumButton.modalPayment.payBtnManuallyWallet')}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                </Box>*/}
                <Box sx={{ width: '80%', m: 'auto' }}>
                  <Divider sx={{ mb: 2 }}>{'or'}</Divider>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12} align='center'>
                      <Button
                        onClick={() => {
                          handleClose();
                          setOpenModalPaymentCustom(true);
                        }}
                        sx={{ height: '100%' }}
                        variant='contained'
                        color='primary'
                      >
                        {t('getPremiumButton.modalPayment.scanTheQR')}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>

                {/*<FrontComponent
                  iframeLink={iframeLink}
                  typeProduct={typeProduct}
                  onValidateBalance={() => {
                    setStatusGetFront('...');
                  }}
                  onPaymentCompleted={() => {
                    setStatusGetFront('');
                    handleClose();
                  }}
                  onSuccess={async (
                    authData,
                    USDCBalance,
                    authToken,
                    brokerType,
                    typeProduct,
                    frontConnection
                  ) => {
                    if (iframeLinkTransfer == false) {
                      let packagePrice = items.price + items.priceFee;
                      USDCBalance = USDCBalance.amount;

                      setStatusGetFront('');

                      const session = JSON.parse(localStorage.getItem('sessionMitox'));
                      const resp = await generateSubscriptionAndGetWallet(
                        session.token,
                        typeProduct
                      );

                      if (resp.status === 200) {
                        const data = JSON.stringify({
                          toAddresses: [
                            {
                              networkId: 'c5dc5d2e-68c1-4261-9a30-90b598738bf5',
                              symbol: 'USDT',
                              address: resp.data[0].product_wallet_address,
                            },
                          ],
                          amountInFiat: packagePrice,
                        });

                        if (packagePrice > USDCBalance) {
                          alert('Balance insuficiente para realizar la compra.');

                          setPayload(authData);
                          setIframeLink(null);
                        } else {
                          setStatusGetFront('');
                          let userId =
                            'mitox-user-' +
                            JSON.parse(localStorage.getItem('sessionMitox')).user.id;

                          const url = `${process.env.NEXT_PUBLIC_FRONT_API_URL}/api/v1/cataloglink?userId=${userId}&enableTransfers=true`;

                          const headers = {
                            'Content-Type': 'application/json',
                            'x-client-id': process.env.NEXT_PUBLIC_CLIENT_ID,
                            'x-client-secret': process.env.NEXT_PUBLIC_CLIENT_SECRET,
                          };

                          fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: data,
                          })
                            .then(response => response.json())
                            .then(data => {
                              iframeLinkTransfer = true;

                              setIframeLink(data.content.iFrameUrl);
                              // Aquí puedes trabajar con los datos de la respuesta
                            })
                            .catch(error => {
                              console.error(error);
                              // Aquí puedes manejar cualquier error ocurrido durante la solicitud
                            });
                        }
                      }

                    }
                  }}
                  onExit={err => {
                    setIframeLink(null);
                    setError(err || null);
                  }}
                />*/}
              </Container>
            )}
          </Box>
        </Dialog>

        <ModalPaymentCustom
          session={session}
          walletAddress={walletAddress}
          open={openModalPaymentCustom}
          handleClose={e => setOpenModalPaymentCustom(false)}
          items={items}
        />
      </>
    );
  }
}
