import { theme } from '@/utils/theme';
import {
  Dialog,
  Container,
  Typography,
  IconButton,
  Icon,
  Divider,
  Button,
  Grid,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import QRCode from 'react-qr-code';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

export default function ModalPaymentCustom({ walletAddress, open, handleClose, session, items }) {
  // function that translate the page
  const { t } = useTranslation('getPremiumBackdrop');

  const [helperCopy, setHelperCopy] = useState({
    show: false,
    message: '',
  });

  const handleCopyIcon = text => {
    const { clipboard } = navigator;

    if (!clipboard || !clipboard.writeText) {
      return setHelperCopy({ show: true, message: 'Cannot access to your clipboard' });
    }

    try {
      clipboard.writeText(text).then(() => {
        setHelperCopy({ show: true, message: 'Copy successful' });
        setTimeout(() => {
          setHelperCopy({ show: false, message: '' });
        }, 1000);
      });
    } catch (error) {
      setHelperCopy({ show: true, message: error.message });
    }
  };

  if (!session) {
    return <></>;
  } else {
    return (
      <>
        <Dialog
          maxWidth='md'
          fullWidth
          style={{ borderRadius: '3rem' }}
          open={open}
          onClose={handleClose}
        >
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={handleClose}>
                <Icon>
                  <CloseIcon />
                </Icon>
              </IconButton>
            </Box>
            <Container maxWidth='lg' sx={{ pb: 4 }}>
              <Box align='center'>
                <Image
                  src='/images/graphics/mainLogo.png'
                  alt='logoReceipt'
                  width={200}
                  height={80}
                />
                {/* <Typography variant='h4' fontWeight={600} color='textPrimary'>
                  Mitox
                </Typography> */}
                <Typography variant='h5' fontWeight={600} color='textPrimary'>
                  {t('getPremiumButton.modalPayment.yourOrderPayment')}
                </Typography>
                <Typography variant='body1' color={theme.palette.text.disabled}>
                  {t('getPremiumButton.modalPayment.scanTheQR')}
                </Typography>
                <Grid container spacing={2} alignItems='center'>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ backgroundColor: '#EEEEEE' }}>
                      <Typography
                        sx={{ pl: 1, pt: 1 }}
                        align='left'
                        variant='body1'
                        color='textPrimary'
                      >
                        <strong>{t('getPremiumButton.modalPayment.emailAddress')}</strong>{' '}
                        {session.user.email}
                      </Typography>

                      <Typography
                        sx={{ pl: 1, pt: 1 }}
                        align='left'
                        variant='body1'
                        color='textPrimary'
                      >
                        <strong>{t('getPremiumButton.modalPayment.phone')}</strong>{' '}
                        {session.user.cellphone}
                      </Typography>
                      <Typography
                        sx={{ pl: 1, pt: 1 }}
                        align='left'
                        variant='body1'
                        color='textPrimary'
                      >
                        <strong>{t('getPremiumButton.modalPayment.date')}</strong>{' '}
                        {new Date().toLocaleDateString()}
                      </Typography>
                    </Box>
                    <Divider sx={{ my: 1, mt: 2 }} />
                    <Grid container spacing={0}>
                      <Grid fontWeight={600} item xs={6}>
                        {t('getPremiumButton.modalPayment.item')}
                      </Grid>
                      <Grid fontWeight={600} item xs={6}>
                        Price (USDT)
                      </Grid>
                      <Grid item xs={6}>
                        {items.title}
                      </Grid>
                      <Grid item xs={6}>
                        ${items.price}
                      </Grid>
                      <Grid item xs={6}>
                        {items.fee}
                      </Grid>
                      <Grid item xs={6}>
                        ${items.priceFee}
                      </Grid>
                    </Grid>
                    <Divider sx={{ my: 1, mb: 1 }} />
                    <Typography
                      sx={{ mb: 2 }}
                      fontWeight={600}
                      align='center'
                      variant='h5'
                      color='textPrimary'
                    >
                      {t('getPremiumButton.modalPayment.total')} $
                      {Number(Number(items.price) + Number(items.priceFee))}
                    </Typography>
                    <Typography align='left' variant='caption' color={theme.palette.text.disabled}>
                      {t('getPremiumButton.modalPayment.feeMessage')}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    {/* QRCODE */}
                    <Box align='center' sx={{ my: 2, mx: 2 }}>
                      <QRCode style={{ width: '80%', height: '80%' }} value={walletAddress} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ width: '80%', m: 'auto' }}>
                <Divider sx={{ mb: 2 }}>
                  {t('getPremiumButton.modalPayment.enterManuallyWallet')}
                </Divider>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <TextField
                      // sx={{ backgroundColor: '#F3F2F5', border: '1px solid #E6E6EB' }}
                      value={walletAddress}
                      disabled
                      fullWidth
                      helperText={helperCopy.show && helperCopy.message}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      onClick={() => handleCopyIcon(walletAddress)}
                      sx={{ height: '100%' }}
                      variant='contained'
                      color='primary'
                    >
                      {/* <Icon> */}
                      <ContentCopyIcon />
                      {/* </Icon> */}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Dialog>
      </>
    );
  }
}
